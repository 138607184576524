import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b685826 = () => interopDefault(import('../src/pages/home/index.vue' /* webpackChunkName: "" */))
const _1450d9ec = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6160643d = () => interopDefault(import('../src/pages/account/callback.vue' /* webpackChunkName: "pages/account/callback" */))
const _1438b1fc = () => interopDefault(import('../src/pages/account/logout.vue' /* webpackChunkName: "pages/account/logout" */))
const _3388728a = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _378e1dfb = () => interopDefault(import('../src/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _bd3700a0 = () => interopDefault(import('../src/pages/debug/index.vue' /* webpackChunkName: "pages/debug/index" */))
const _1fde3b21 = () => interopDefault(import('../src/pages/exhibitions/index.vue' /* webpackChunkName: "pages/exhibitions/index" */))
const _9c3a58e6 = () => interopDefault(import('../src/pages/galleries/index.vue' /* webpackChunkName: "pages/galleries/index" */))
const _651d4ffe = () => interopDefault(import('../src/pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _c630e5fe = () => interopDefault(import('../src/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _a4775da0 = () => interopDefault(import('../src/pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _3faaaeba = () => interopDefault(import('../src/pages/themes/index.vue' /* webpackChunkName: "pages/themes/index" */))
const _329b6cbe = () => interopDefault(import('../src/pages/trending/index.vue' /* webpackChunkName: "pages/trending/index" */))
const _055d604e = () => interopDefault(import('../src/pages/microsite/DS4CH.eu.vue' /* webpackChunkName: "pages/microsite/DS4CH.eu" */))
const _059fd4be = () => interopDefault(import('../src/pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _60a7a7d9 = () => interopDefault(import('../src/pages/collections/persons-or-places.vue' /* webpackChunkName: "" */))
const _374754c6 = () => interopDefault(import('../src/pages/contentful/category-suggest/index.vue' /* webpackChunkName: "pages/contentful/category-suggest/index" */))
const _15ffd630 = () => interopDefault(import('../src/pages/contentful/disabled-single-line/index.vue' /* webpackChunkName: "pages/contentful/disabled-single-line/index" */))
const _6ef8d8d8 = () => interopDefault(import('../src/pages/contentful/entity-harvester/index.vue' /* webpackChunkName: "pages/contentful/entity-harvester/index" */))
const _ce4e66fc = () => interopDefault(import('../src/pages/contentful/entity-suggest/index.vue' /* webpackChunkName: "pages/contentful/entity-suggest/index" */))
const _0cb2bd5c = () => interopDefault(import('../src/pages/contentful/image-harvester/index.vue' /* webpackChunkName: "pages/contentful/image-harvester/index" */))
const _26519902 = () => interopDefault(import('../src/pages/contentful/record-harvester/index.vue' /* webpackChunkName: "pages/contentful/record-harvester/index" */))
const _528a19c7 = () => interopDefault(import('../src/pages/contentful/set-suggest/index.vue' /* webpackChunkName: "pages/contentful/set-suggest/index" */))
const _d8b2bcee = () => interopDefault(import('../src/pages/contentful/slug-validation/index.vue' /* webpackChunkName: "pages/contentful/slug-validation/index" */))
const _0dbf7648 = () => interopDefault(import('../src/pages/debug/oembed.vue' /* webpackChunkName: "pages/debug/oembed" */))
const _107b567c = () => interopDefault(import('../src/pages/collections/_type/index.vue' /* webpackChunkName: "pages/collections/_type/index" */))
const _49e81f40 = () => interopDefault(import('../src/pages/exhibitions/_exhibition/index.vue' /* webpackChunkName: "pages/exhibitions/_exhibition/index" */))
const _4e35a988 = () => interopDefault(import('../src/pages/exhibitions/_exhibition/credits.vue' /* webpackChunkName: "pages/exhibitions/_exhibition/credits" */))
const _f9792be0 = () => interopDefault(import('../src/pages/exhibitions/_exhibition/_chapter.vue' /* webpackChunkName: "pages/exhibitions/_exhibition/_chapter" */))
const _b55f0eee = () => interopDefault(import('../src/pages/collections/_type/_.vue' /* webpackChunkName: "pages/collections/_type/_" */))
const _05b8d788 = () => interopDefault(import('../src/pages/blog/_.vue' /* webpackChunkName: "pages/blog/_" */))
const _0ddb261a = () => interopDefault(import('../src/pages/galleries/_.vue' /* webpackChunkName: "pages/galleries/_" */))
const _f70feb8e = () => interopDefault(import('../src/pages/item/_.vue' /* webpackChunkName: "pages/item/_" */))
const _a75613a0 = () => interopDefault(import('../src/pages/themes/_.vue' /* webpackChunkName: "pages/themes/_" */))
const _57509004 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/bg",
    component: _5b685826,
    name: "home___bg"
  }, {
    path: "/bg",
    component: _1450d9ec,
    name: "index___bg"
  }, {
    path: "/cs",
    component: _5b685826,
    name: "home___cs"
  }, {
    path: "/cs",
    component: _1450d9ec,
    name: "index___cs"
  }, {
    path: "/da",
    component: _5b685826,
    name: "home___da"
  }, {
    path: "/da",
    component: _1450d9ec,
    name: "index___da"
  }, {
    path: "/de",
    component: _5b685826,
    name: "home___de"
  }, {
    path: "/de",
    component: _1450d9ec,
    name: "index___de"
  }, {
    path: "/el",
    component: _5b685826,
    name: "home___el"
  }, {
    path: "/el",
    component: _1450d9ec,
    name: "index___el"
  }, {
    path: "/en",
    component: _5b685826,
    name: "home___en"
  }, {
    path: "/en",
    component: _1450d9ec,
    name: "index___en"
  }, {
    path: "/es",
    component: _5b685826,
    name: "home___es"
  }, {
    path: "/es",
    component: _1450d9ec,
    name: "index___es"
  }, {
    path: "/et",
    component: _5b685826,
    name: "home___et"
  }, {
    path: "/et",
    component: _1450d9ec,
    name: "index___et"
  }, {
    path: "/eu",
    component: _5b685826,
    name: "home___eu"
  }, {
    path: "/eu",
    component: _1450d9ec,
    name: "index___eu"
  }, {
    path: "/fi",
    component: _5b685826,
    name: "home___fi"
  }, {
    path: "/fi",
    component: _1450d9ec,
    name: "index___fi"
  }, {
    path: "/fr",
    component: _5b685826,
    name: "home___fr"
  }, {
    path: "/fr",
    component: _1450d9ec,
    name: "index___fr"
  }, {
    path: "/ga",
    component: _5b685826,
    name: "home___ga"
  }, {
    path: "/ga",
    component: _1450d9ec,
    name: "index___ga"
  }, {
    path: "/hr",
    component: _5b685826,
    name: "home___hr"
  }, {
    path: "/hr",
    component: _1450d9ec,
    name: "index___hr"
  }, {
    path: "/hu",
    component: _5b685826,
    name: "home___hu"
  }, {
    path: "/hu",
    component: _1450d9ec,
    name: "index___hu"
  }, {
    path: "/it",
    component: _5b685826,
    name: "home___it"
  }, {
    path: "/it",
    component: _1450d9ec,
    name: "index___it"
  }, {
    path: "/lt",
    component: _5b685826,
    name: "home___lt"
  }, {
    path: "/lt",
    component: _1450d9ec,
    name: "index___lt"
  }, {
    path: "/lv",
    component: _5b685826,
    name: "home___lv"
  }, {
    path: "/lv",
    component: _1450d9ec,
    name: "index___lv"
  }, {
    path: "/mt",
    component: _5b685826,
    name: "home___mt"
  }, {
    path: "/mt",
    component: _1450d9ec,
    name: "index___mt"
  }, {
    path: "/nl",
    component: _5b685826,
    name: "home___nl"
  }, {
    path: "/nl",
    component: _1450d9ec,
    name: "index___nl"
  }, {
    path: "/pl",
    component: _5b685826,
    name: "home___pl"
  }, {
    path: "/pl",
    component: _1450d9ec,
    name: "index___pl"
  }, {
    path: "/pt",
    component: _5b685826,
    name: "home___pt"
  }, {
    path: "/pt",
    component: _1450d9ec,
    name: "index___pt"
  }, {
    path: "/ro",
    component: _5b685826,
    name: "home___ro"
  }, {
    path: "/ro",
    component: _1450d9ec,
    name: "index___ro"
  }, {
    path: "/sk",
    component: _5b685826,
    name: "home___sk"
  }, {
    path: "/sk",
    component: _1450d9ec,
    name: "index___sk"
  }, {
    path: "/sl",
    component: _5b685826,
    name: "home___sl"
  }, {
    path: "/sl",
    component: _1450d9ec,
    name: "index___sl"
  }, {
    path: "/sv",
    component: _5b685826,
    name: "home___sv"
  }, {
    path: "/sv",
    component: _1450d9ec,
    name: "index___sv"
  }, {
    path: "/account/callback",
    component: _6160643d,
    name: "account-callback"
  }, {
    path: "/account/logout",
    component: _1438b1fc,
    name: "account-logout"
  }, {
    path: "/bg/account",
    component: _3388728a,
    name: "account___bg"
  }, {
    path: "/bg/blog",
    component: _378e1dfb,
    name: "blog___bg"
  }, {
    path: "/bg/debug",
    component: _bd3700a0,
    name: "debug___bg"
  }, {
    path: "/bg/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___bg"
  }, {
    path: "/bg/galleries",
    component: _9c3a58e6,
    name: "galleries___bg"
  }, {
    path: "/bg/media",
    component: _651d4ffe,
    name: "media___bg"
  }, {
    path: "/bg/search",
    component: _c630e5fe,
    name: "search___bg"
  }, {
    path: "/bg/stories",
    component: _a4775da0,
    name: "stories___bg"
  }, {
    path: "/bg/themes",
    component: _3faaaeba,
    name: "themes___bg"
  }, {
    path: "/bg/trending",
    component: _329b6cbe,
    name: "trending___bg"
  }, {
    path: "/cs/account",
    component: _3388728a,
    name: "account___cs"
  }, {
    path: "/cs/blog",
    component: _378e1dfb,
    name: "blog___cs"
  }, {
    path: "/cs/debug",
    component: _bd3700a0,
    name: "debug___cs"
  }, {
    path: "/cs/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___cs"
  }, {
    path: "/cs/galleries",
    component: _9c3a58e6,
    name: "galleries___cs"
  }, {
    path: "/cs/media",
    component: _651d4ffe,
    name: "media___cs"
  }, {
    path: "/cs/search",
    component: _c630e5fe,
    name: "search___cs"
  }, {
    path: "/cs/stories",
    component: _a4775da0,
    name: "stories___cs"
  }, {
    path: "/cs/themes",
    component: _3faaaeba,
    name: "themes___cs"
  }, {
    path: "/cs/trending",
    component: _329b6cbe,
    name: "trending___cs"
  }, {
    path: "/da/account",
    component: _3388728a,
    name: "account___da"
  }, {
    path: "/da/blog",
    component: _378e1dfb,
    name: "blog___da"
  }, {
    path: "/da/debug",
    component: _bd3700a0,
    name: "debug___da"
  }, {
    path: "/da/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___da"
  }, {
    path: "/da/galleries",
    component: _9c3a58e6,
    name: "galleries___da"
  }, {
    path: "/da/media",
    component: _651d4ffe,
    name: "media___da"
  }, {
    path: "/da/search",
    component: _c630e5fe,
    name: "search___da"
  }, {
    path: "/da/stories",
    component: _a4775da0,
    name: "stories___da"
  }, {
    path: "/da/themes",
    component: _3faaaeba,
    name: "themes___da"
  }, {
    path: "/da/trending",
    component: _329b6cbe,
    name: "trending___da"
  }, {
    path: "/de/account",
    component: _3388728a,
    name: "account___de"
  }, {
    path: "/de/blog",
    component: _378e1dfb,
    name: "blog___de"
  }, {
    path: "/de/debug",
    component: _bd3700a0,
    name: "debug___de"
  }, {
    path: "/de/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___de"
  }, {
    path: "/de/galleries",
    component: _9c3a58e6,
    name: "galleries___de"
  }, {
    path: "/de/media",
    component: _651d4ffe,
    name: "media___de"
  }, {
    path: "/de/search",
    component: _c630e5fe,
    name: "search___de"
  }, {
    path: "/de/stories",
    component: _a4775da0,
    name: "stories___de"
  }, {
    path: "/de/themes",
    component: _3faaaeba,
    name: "themes___de"
  }, {
    path: "/de/trending",
    component: _329b6cbe,
    name: "trending___de"
  }, {
    path: "/el/account",
    component: _3388728a,
    name: "account___el"
  }, {
    path: "/el/blog",
    component: _378e1dfb,
    name: "blog___el"
  }, {
    path: "/el/debug",
    component: _bd3700a0,
    name: "debug___el"
  }, {
    path: "/el/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___el"
  }, {
    path: "/el/galleries",
    component: _9c3a58e6,
    name: "galleries___el"
  }, {
    path: "/el/media",
    component: _651d4ffe,
    name: "media___el"
  }, {
    path: "/el/search",
    component: _c630e5fe,
    name: "search___el"
  }, {
    path: "/el/stories",
    component: _a4775da0,
    name: "stories___el"
  }, {
    path: "/el/themes",
    component: _3faaaeba,
    name: "themes___el"
  }, {
    path: "/el/trending",
    component: _329b6cbe,
    name: "trending___el"
  }, {
    path: "/en/account",
    component: _3388728a,
    name: "account___en"
  }, {
    path: "/en/blog",
    component: _378e1dfb,
    name: "blog___en"
  }, {
    path: "/en/debug",
    component: _bd3700a0,
    name: "debug___en"
  }, {
    path: "/en/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___en"
  }, {
    path: "/en/galleries",
    component: _9c3a58e6,
    name: "galleries___en"
  }, {
    path: "/en/media",
    component: _651d4ffe,
    name: "media___en"
  }, {
    path: "/en/search",
    component: _c630e5fe,
    name: "search___en"
  }, {
    path: "/en/stories",
    component: _a4775da0,
    name: "stories___en"
  }, {
    path: "/en/themes",
    component: _3faaaeba,
    name: "themes___en"
  }, {
    path: "/en/trending",
    component: _329b6cbe,
    name: "trending___en"
  }, {
    path: "/es/account",
    component: _3388728a,
    name: "account___es"
  }, {
    path: "/es/blog",
    component: _378e1dfb,
    name: "blog___es"
  }, {
    path: "/es/debug",
    component: _bd3700a0,
    name: "debug___es"
  }, {
    path: "/es/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___es"
  }, {
    path: "/es/galleries",
    component: _9c3a58e6,
    name: "galleries___es"
  }, {
    path: "/es/media",
    component: _651d4ffe,
    name: "media___es"
  }, {
    path: "/es/search",
    component: _c630e5fe,
    name: "search___es"
  }, {
    path: "/es/stories",
    component: _a4775da0,
    name: "stories___es"
  }, {
    path: "/es/themes",
    component: _3faaaeba,
    name: "themes___es"
  }, {
    path: "/es/trending",
    component: _329b6cbe,
    name: "trending___es"
  }, {
    path: "/et/account",
    component: _3388728a,
    name: "account___et"
  }, {
    path: "/et/blog",
    component: _378e1dfb,
    name: "blog___et"
  }, {
    path: "/et/debug",
    component: _bd3700a0,
    name: "debug___et"
  }, {
    path: "/et/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___et"
  }, {
    path: "/et/galleries",
    component: _9c3a58e6,
    name: "galleries___et"
  }, {
    path: "/et/media",
    component: _651d4ffe,
    name: "media___et"
  }, {
    path: "/et/search",
    component: _c630e5fe,
    name: "search___et"
  }, {
    path: "/et/stories",
    component: _a4775da0,
    name: "stories___et"
  }, {
    path: "/et/themes",
    component: _3faaaeba,
    name: "themes___et"
  }, {
    path: "/et/trending",
    component: _329b6cbe,
    name: "trending___et"
  }, {
    path: "/eu/account",
    component: _3388728a,
    name: "account___eu"
  }, {
    path: "/eu/blog",
    component: _378e1dfb,
    name: "blog___eu"
  }, {
    path: "/eu/debug",
    component: _bd3700a0,
    name: "debug___eu"
  }, {
    path: "/eu/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___eu"
  }, {
    path: "/eu/galleries",
    component: _9c3a58e6,
    name: "galleries___eu"
  }, {
    path: "/eu/media",
    component: _651d4ffe,
    name: "media___eu"
  }, {
    path: "/eu/search",
    component: _c630e5fe,
    name: "search___eu"
  }, {
    path: "/eu/stories",
    component: _a4775da0,
    name: "stories___eu"
  }, {
    path: "/eu/themes",
    component: _3faaaeba,
    name: "themes___eu"
  }, {
    path: "/eu/trending",
    component: _329b6cbe,
    name: "trending___eu"
  }, {
    path: "/fi/account",
    component: _3388728a,
    name: "account___fi"
  }, {
    path: "/fi/blog",
    component: _378e1dfb,
    name: "blog___fi"
  }, {
    path: "/fi/debug",
    component: _bd3700a0,
    name: "debug___fi"
  }, {
    path: "/fi/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___fi"
  }, {
    path: "/fi/galleries",
    component: _9c3a58e6,
    name: "galleries___fi"
  }, {
    path: "/fi/media",
    component: _651d4ffe,
    name: "media___fi"
  }, {
    path: "/fi/search",
    component: _c630e5fe,
    name: "search___fi"
  }, {
    path: "/fi/stories",
    component: _a4775da0,
    name: "stories___fi"
  }, {
    path: "/fi/themes",
    component: _3faaaeba,
    name: "themes___fi"
  }, {
    path: "/fi/trending",
    component: _329b6cbe,
    name: "trending___fi"
  }, {
    path: "/fr/account",
    component: _3388728a,
    name: "account___fr"
  }, {
    path: "/fr/blog",
    component: _378e1dfb,
    name: "blog___fr"
  }, {
    path: "/fr/debug",
    component: _bd3700a0,
    name: "debug___fr"
  }, {
    path: "/fr/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___fr"
  }, {
    path: "/fr/galleries",
    component: _9c3a58e6,
    name: "galleries___fr"
  }, {
    path: "/fr/media",
    component: _651d4ffe,
    name: "media___fr"
  }, {
    path: "/fr/search",
    component: _c630e5fe,
    name: "search___fr"
  }, {
    path: "/fr/stories",
    component: _a4775da0,
    name: "stories___fr"
  }, {
    path: "/fr/themes",
    component: _3faaaeba,
    name: "themes___fr"
  }, {
    path: "/fr/trending",
    component: _329b6cbe,
    name: "trending___fr"
  }, {
    path: "/ga/account",
    component: _3388728a,
    name: "account___ga"
  }, {
    path: "/ga/blog",
    component: _378e1dfb,
    name: "blog___ga"
  }, {
    path: "/ga/debug",
    component: _bd3700a0,
    name: "debug___ga"
  }, {
    path: "/ga/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___ga"
  }, {
    path: "/ga/galleries",
    component: _9c3a58e6,
    name: "galleries___ga"
  }, {
    path: "/ga/media",
    component: _651d4ffe,
    name: "media___ga"
  }, {
    path: "/ga/search",
    component: _c630e5fe,
    name: "search___ga"
  }, {
    path: "/ga/stories",
    component: _a4775da0,
    name: "stories___ga"
  }, {
    path: "/ga/themes",
    component: _3faaaeba,
    name: "themes___ga"
  }, {
    path: "/ga/trending",
    component: _329b6cbe,
    name: "trending___ga"
  }, {
    path: "/hr/account",
    component: _3388728a,
    name: "account___hr"
  }, {
    path: "/hr/blog",
    component: _378e1dfb,
    name: "blog___hr"
  }, {
    path: "/hr/debug",
    component: _bd3700a0,
    name: "debug___hr"
  }, {
    path: "/hr/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___hr"
  }, {
    path: "/hr/galleries",
    component: _9c3a58e6,
    name: "galleries___hr"
  }, {
    path: "/hr/media",
    component: _651d4ffe,
    name: "media___hr"
  }, {
    path: "/hr/search",
    component: _c630e5fe,
    name: "search___hr"
  }, {
    path: "/hr/stories",
    component: _a4775da0,
    name: "stories___hr"
  }, {
    path: "/hr/themes",
    component: _3faaaeba,
    name: "themes___hr"
  }, {
    path: "/hr/trending",
    component: _329b6cbe,
    name: "trending___hr"
  }, {
    path: "/hu/account",
    component: _3388728a,
    name: "account___hu"
  }, {
    path: "/hu/blog",
    component: _378e1dfb,
    name: "blog___hu"
  }, {
    path: "/hu/debug",
    component: _bd3700a0,
    name: "debug___hu"
  }, {
    path: "/hu/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___hu"
  }, {
    path: "/hu/galleries",
    component: _9c3a58e6,
    name: "galleries___hu"
  }, {
    path: "/hu/media",
    component: _651d4ffe,
    name: "media___hu"
  }, {
    path: "/hu/search",
    component: _c630e5fe,
    name: "search___hu"
  }, {
    path: "/hu/stories",
    component: _a4775da0,
    name: "stories___hu"
  }, {
    path: "/hu/themes",
    component: _3faaaeba,
    name: "themes___hu"
  }, {
    path: "/hu/trending",
    component: _329b6cbe,
    name: "trending___hu"
  }, {
    path: "/it/account",
    component: _3388728a,
    name: "account___it"
  }, {
    path: "/it/blog",
    component: _378e1dfb,
    name: "blog___it"
  }, {
    path: "/it/debug",
    component: _bd3700a0,
    name: "debug___it"
  }, {
    path: "/it/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___it"
  }, {
    path: "/it/galleries",
    component: _9c3a58e6,
    name: "galleries___it"
  }, {
    path: "/it/media",
    component: _651d4ffe,
    name: "media___it"
  }, {
    path: "/it/search",
    component: _c630e5fe,
    name: "search___it"
  }, {
    path: "/it/stories",
    component: _a4775da0,
    name: "stories___it"
  }, {
    path: "/it/themes",
    component: _3faaaeba,
    name: "themes___it"
  }, {
    path: "/it/trending",
    component: _329b6cbe,
    name: "trending___it"
  }, {
    path: "/lt/account",
    component: _3388728a,
    name: "account___lt"
  }, {
    path: "/lt/blog",
    component: _378e1dfb,
    name: "blog___lt"
  }, {
    path: "/lt/debug",
    component: _bd3700a0,
    name: "debug___lt"
  }, {
    path: "/lt/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___lt"
  }, {
    path: "/lt/galleries",
    component: _9c3a58e6,
    name: "galleries___lt"
  }, {
    path: "/lt/media",
    component: _651d4ffe,
    name: "media___lt"
  }, {
    path: "/lt/search",
    component: _c630e5fe,
    name: "search___lt"
  }, {
    path: "/lt/stories",
    component: _a4775da0,
    name: "stories___lt"
  }, {
    path: "/lt/themes",
    component: _3faaaeba,
    name: "themes___lt"
  }, {
    path: "/lt/trending",
    component: _329b6cbe,
    name: "trending___lt"
  }, {
    path: "/lv/account",
    component: _3388728a,
    name: "account___lv"
  }, {
    path: "/lv/blog",
    component: _378e1dfb,
    name: "blog___lv"
  }, {
    path: "/lv/debug",
    component: _bd3700a0,
    name: "debug___lv"
  }, {
    path: "/lv/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___lv"
  }, {
    path: "/lv/galleries",
    component: _9c3a58e6,
    name: "galleries___lv"
  }, {
    path: "/lv/media",
    component: _651d4ffe,
    name: "media___lv"
  }, {
    path: "/lv/search",
    component: _c630e5fe,
    name: "search___lv"
  }, {
    path: "/lv/stories",
    component: _a4775da0,
    name: "stories___lv"
  }, {
    path: "/lv/themes",
    component: _3faaaeba,
    name: "themes___lv"
  }, {
    path: "/lv/trending",
    component: _329b6cbe,
    name: "trending___lv"
  }, {
    path: "/microsite/DS4CH.eu",
    component: _055d604e,
    name: "microsite-DS4CH.eu"
  }, {
    path: "/mt/account",
    component: _3388728a,
    name: "account___mt"
  }, {
    path: "/mt/blog",
    component: _378e1dfb,
    name: "blog___mt"
  }, {
    path: "/mt/debug",
    component: _bd3700a0,
    name: "debug___mt"
  }, {
    path: "/mt/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___mt"
  }, {
    path: "/mt/galleries",
    component: _9c3a58e6,
    name: "galleries___mt"
  }, {
    path: "/mt/media",
    component: _651d4ffe,
    name: "media___mt"
  }, {
    path: "/mt/search",
    component: _c630e5fe,
    name: "search___mt"
  }, {
    path: "/mt/stories",
    component: _a4775da0,
    name: "stories___mt"
  }, {
    path: "/mt/themes",
    component: _3faaaeba,
    name: "themes___mt"
  }, {
    path: "/mt/trending",
    component: _329b6cbe,
    name: "trending___mt"
  }, {
    path: "/nl/account",
    component: _3388728a,
    name: "account___nl"
  }, {
    path: "/nl/blog",
    component: _378e1dfb,
    name: "blog___nl"
  }, {
    path: "/nl/debug",
    component: _bd3700a0,
    name: "debug___nl"
  }, {
    path: "/nl/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___nl"
  }, {
    path: "/nl/galleries",
    component: _9c3a58e6,
    name: "galleries___nl"
  }, {
    path: "/nl/media",
    component: _651d4ffe,
    name: "media___nl"
  }, {
    path: "/nl/search",
    component: _c630e5fe,
    name: "search___nl"
  }, {
    path: "/nl/stories",
    component: _a4775da0,
    name: "stories___nl"
  }, {
    path: "/nl/themes",
    component: _3faaaeba,
    name: "themes___nl"
  }, {
    path: "/nl/trending",
    component: _329b6cbe,
    name: "trending___nl"
  }, {
    path: "/pl/account",
    component: _3388728a,
    name: "account___pl"
  }, {
    path: "/pl/blog",
    component: _378e1dfb,
    name: "blog___pl"
  }, {
    path: "/pl/debug",
    component: _bd3700a0,
    name: "debug___pl"
  }, {
    path: "/pl/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___pl"
  }, {
    path: "/pl/galleries",
    component: _9c3a58e6,
    name: "galleries___pl"
  }, {
    path: "/pl/media",
    component: _651d4ffe,
    name: "media___pl"
  }, {
    path: "/pl/search",
    component: _c630e5fe,
    name: "search___pl"
  }, {
    path: "/pl/stories",
    component: _a4775da0,
    name: "stories___pl"
  }, {
    path: "/pl/themes",
    component: _3faaaeba,
    name: "themes___pl"
  }, {
    path: "/pl/trending",
    component: _329b6cbe,
    name: "trending___pl"
  }, {
    path: "/pt/account",
    component: _3388728a,
    name: "account___pt"
  }, {
    path: "/pt/blog",
    component: _378e1dfb,
    name: "blog___pt"
  }, {
    path: "/pt/debug",
    component: _bd3700a0,
    name: "debug___pt"
  }, {
    path: "/pt/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___pt"
  }, {
    path: "/pt/galleries",
    component: _9c3a58e6,
    name: "galleries___pt"
  }, {
    path: "/pt/media",
    component: _651d4ffe,
    name: "media___pt"
  }, {
    path: "/pt/search",
    component: _c630e5fe,
    name: "search___pt"
  }, {
    path: "/pt/stories",
    component: _a4775da0,
    name: "stories___pt"
  }, {
    path: "/pt/themes",
    component: _3faaaeba,
    name: "themes___pt"
  }, {
    path: "/pt/trending",
    component: _329b6cbe,
    name: "trending___pt"
  }, {
    path: "/ro/account",
    component: _3388728a,
    name: "account___ro"
  }, {
    path: "/ro/blog",
    component: _378e1dfb,
    name: "blog___ro"
  }, {
    path: "/ro/debug",
    component: _bd3700a0,
    name: "debug___ro"
  }, {
    path: "/ro/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___ro"
  }, {
    path: "/ro/galleries",
    component: _9c3a58e6,
    name: "galleries___ro"
  }, {
    path: "/ro/media",
    component: _651d4ffe,
    name: "media___ro"
  }, {
    path: "/ro/search",
    component: _c630e5fe,
    name: "search___ro"
  }, {
    path: "/ro/stories",
    component: _a4775da0,
    name: "stories___ro"
  }, {
    path: "/ro/themes",
    component: _3faaaeba,
    name: "themes___ro"
  }, {
    path: "/ro/trending",
    component: _329b6cbe,
    name: "trending___ro"
  }, {
    path: "/sk/account",
    component: _3388728a,
    name: "account___sk"
  }, {
    path: "/sk/blog",
    component: _378e1dfb,
    name: "blog___sk"
  }, {
    path: "/sk/debug",
    component: _bd3700a0,
    name: "debug___sk"
  }, {
    path: "/sk/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___sk"
  }, {
    path: "/sk/galleries",
    component: _9c3a58e6,
    name: "galleries___sk"
  }, {
    path: "/sk/media",
    component: _651d4ffe,
    name: "media___sk"
  }, {
    path: "/sk/search",
    component: _c630e5fe,
    name: "search___sk"
  }, {
    path: "/sk/stories",
    component: _a4775da0,
    name: "stories___sk"
  }, {
    path: "/sk/themes",
    component: _3faaaeba,
    name: "themes___sk"
  }, {
    path: "/sk/trending",
    component: _329b6cbe,
    name: "trending___sk"
  }, {
    path: "/sl/account",
    component: _3388728a,
    name: "account___sl"
  }, {
    path: "/sl/blog",
    component: _378e1dfb,
    name: "blog___sl"
  }, {
    path: "/sl/debug",
    component: _bd3700a0,
    name: "debug___sl"
  }, {
    path: "/sl/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___sl"
  }, {
    path: "/sl/galleries",
    component: _9c3a58e6,
    name: "galleries___sl"
  }, {
    path: "/sl/media",
    component: _651d4ffe,
    name: "media___sl"
  }, {
    path: "/sl/search",
    component: _c630e5fe,
    name: "search___sl"
  }, {
    path: "/sl/stories",
    component: _a4775da0,
    name: "stories___sl"
  }, {
    path: "/sl/themes",
    component: _3faaaeba,
    name: "themes___sl"
  }, {
    path: "/sl/trending",
    component: _329b6cbe,
    name: "trending___sl"
  }, {
    path: "/sv/account",
    component: _3388728a,
    name: "account___sv"
  }, {
    path: "/sv/blog",
    component: _378e1dfb,
    name: "blog___sv"
  }, {
    path: "/sv/debug",
    component: _bd3700a0,
    name: "debug___sv"
  }, {
    path: "/sv/exhibitions",
    component: _1fde3b21,
    name: "exhibitions___sv"
  }, {
    path: "/sv/galleries",
    component: _9c3a58e6,
    name: "galleries___sv"
  }, {
    path: "/sv/media",
    component: _651d4ffe,
    name: "media___sv"
  }, {
    path: "/sv/search",
    component: _c630e5fe,
    name: "search___sv"
  }, {
    path: "/sv/stories",
    component: _a4775da0,
    name: "stories___sv"
  }, {
    path: "/sv/themes",
    component: _3faaaeba,
    name: "themes___sv"
  }, {
    path: "/sv/trending",
    component: _329b6cbe,
    name: "trending___sv"
  }, {
    path: "/bg/account/login",
    component: _059fd4be,
    name: "account-login___bg"
  }, {
    path: "/bg/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___bg"
  }, {
    path: "/bg/collections/places",
    component: _60a7a7d9,
    name: "collections-places___bg"
  }, {
    path: "/bg/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___bg"
  }, {
    path: "/bg/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___bg"
  }, {
    path: "/bg/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___bg"
  }, {
    path: "/bg/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___bg"
  }, {
    path: "/bg/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___bg"
  }, {
    path: "/bg/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___bg"
  }, {
    path: "/bg/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___bg"
  }, {
    path: "/bg/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___bg"
  }, {
    path: "/bg/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___bg"
  }, {
    path: "/cs/account/login",
    component: _059fd4be,
    name: "account-login___cs"
  }, {
    path: "/cs/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___cs"
  }, {
    path: "/cs/collections/places",
    component: _60a7a7d9,
    name: "collections-places___cs"
  }, {
    path: "/cs/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___cs"
  }, {
    path: "/cs/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___cs"
  }, {
    path: "/cs/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___cs"
  }, {
    path: "/cs/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___cs"
  }, {
    path: "/cs/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___cs"
  }, {
    path: "/cs/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___cs"
  }, {
    path: "/cs/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___cs"
  }, {
    path: "/cs/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___cs"
  }, {
    path: "/cs/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___cs"
  }, {
    path: "/da/account/login",
    component: _059fd4be,
    name: "account-login___da"
  }, {
    path: "/da/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___da"
  }, {
    path: "/da/collections/places",
    component: _60a7a7d9,
    name: "collections-places___da"
  }, {
    path: "/da/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___da"
  }, {
    path: "/da/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___da"
  }, {
    path: "/da/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___da"
  }, {
    path: "/da/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___da"
  }, {
    path: "/da/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___da"
  }, {
    path: "/da/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___da"
  }, {
    path: "/da/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___da"
  }, {
    path: "/da/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___da"
  }, {
    path: "/da/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___da"
  }, {
    path: "/de/account/login",
    component: _059fd4be,
    name: "account-login___de"
  }, {
    path: "/de/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___de"
  }, {
    path: "/de/collections/places",
    component: _60a7a7d9,
    name: "collections-places___de"
  }, {
    path: "/de/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___de"
  }, {
    path: "/de/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___de"
  }, {
    path: "/de/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___de"
  }, {
    path: "/de/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___de"
  }, {
    path: "/de/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___de"
  }, {
    path: "/de/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___de"
  }, {
    path: "/de/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___de"
  }, {
    path: "/de/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___de"
  }, {
    path: "/de/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___de"
  }, {
    path: "/el/account/login",
    component: _059fd4be,
    name: "account-login___el"
  }, {
    path: "/el/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___el"
  }, {
    path: "/el/collections/places",
    component: _60a7a7d9,
    name: "collections-places___el"
  }, {
    path: "/el/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___el"
  }, {
    path: "/el/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___el"
  }, {
    path: "/el/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___el"
  }, {
    path: "/el/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___el"
  }, {
    path: "/el/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___el"
  }, {
    path: "/el/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___el"
  }, {
    path: "/el/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___el"
  }, {
    path: "/el/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___el"
  }, {
    path: "/el/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___el"
  }, {
    path: "/en/account/login",
    component: _059fd4be,
    name: "account-login___en"
  }, {
    path: "/en/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___en"
  }, {
    path: "/en/collections/places",
    component: _60a7a7d9,
    name: "collections-places___en"
  }, {
    path: "/en/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___en"
  }, {
    path: "/en/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___en"
  }, {
    path: "/en/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___en"
  }, {
    path: "/en/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___en"
  }, {
    path: "/en/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___en"
  }, {
    path: "/en/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___en"
  }, {
    path: "/en/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___en"
  }, {
    path: "/en/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___en"
  }, {
    path: "/en/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___en"
  }, {
    path: "/es/account/login",
    component: _059fd4be,
    name: "account-login___es"
  }, {
    path: "/es/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___es"
  }, {
    path: "/es/collections/places",
    component: _60a7a7d9,
    name: "collections-places___es"
  }, {
    path: "/es/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___es"
  }, {
    path: "/es/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___es"
  }, {
    path: "/es/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___es"
  }, {
    path: "/es/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___es"
  }, {
    path: "/es/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___es"
  }, {
    path: "/es/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___es"
  }, {
    path: "/es/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___es"
  }, {
    path: "/es/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___es"
  }, {
    path: "/es/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___es"
  }, {
    path: "/et/account/login",
    component: _059fd4be,
    name: "account-login___et"
  }, {
    path: "/et/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___et"
  }, {
    path: "/et/collections/places",
    component: _60a7a7d9,
    name: "collections-places___et"
  }, {
    path: "/et/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___et"
  }, {
    path: "/et/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___et"
  }, {
    path: "/et/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___et"
  }, {
    path: "/et/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___et"
  }, {
    path: "/et/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___et"
  }, {
    path: "/et/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___et"
  }, {
    path: "/et/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___et"
  }, {
    path: "/et/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___et"
  }, {
    path: "/et/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___et"
  }, {
    path: "/eu/account/login",
    component: _059fd4be,
    name: "account-login___eu"
  }, {
    path: "/eu/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___eu"
  }, {
    path: "/eu/collections/places",
    component: _60a7a7d9,
    name: "collections-places___eu"
  }, {
    path: "/eu/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___eu"
  }, {
    path: "/eu/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___eu"
  }, {
    path: "/eu/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___eu"
  }, {
    path: "/eu/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___eu"
  }, {
    path: "/eu/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___eu"
  }, {
    path: "/eu/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___eu"
  }, {
    path: "/eu/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___eu"
  }, {
    path: "/eu/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___eu"
  }, {
    path: "/eu/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___eu"
  }, {
    path: "/fi/account/login",
    component: _059fd4be,
    name: "account-login___fi"
  }, {
    path: "/fi/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___fi"
  }, {
    path: "/fi/collections/places",
    component: _60a7a7d9,
    name: "collections-places___fi"
  }, {
    path: "/fi/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___fi"
  }, {
    path: "/fi/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___fi"
  }, {
    path: "/fi/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___fi"
  }, {
    path: "/fi/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___fi"
  }, {
    path: "/fi/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___fi"
  }, {
    path: "/fi/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___fi"
  }, {
    path: "/fi/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___fi"
  }, {
    path: "/fi/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___fi"
  }, {
    path: "/fi/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___fi"
  }, {
    path: "/fr/account/login",
    component: _059fd4be,
    name: "account-login___fr"
  }, {
    path: "/fr/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___fr"
  }, {
    path: "/fr/collections/places",
    component: _60a7a7d9,
    name: "collections-places___fr"
  }, {
    path: "/fr/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___fr"
  }, {
    path: "/fr/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___fr"
  }, {
    path: "/fr/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___fr"
  }, {
    path: "/fr/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___fr"
  }, {
    path: "/fr/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___fr"
  }, {
    path: "/fr/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___fr"
  }, {
    path: "/fr/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___fr"
  }, {
    path: "/fr/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___fr"
  }, {
    path: "/fr/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___fr"
  }, {
    path: "/ga/account/login",
    component: _059fd4be,
    name: "account-login___ga"
  }, {
    path: "/ga/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___ga"
  }, {
    path: "/ga/collections/places",
    component: _60a7a7d9,
    name: "collections-places___ga"
  }, {
    path: "/ga/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___ga"
  }, {
    path: "/ga/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___ga"
  }, {
    path: "/ga/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___ga"
  }, {
    path: "/ga/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___ga"
  }, {
    path: "/ga/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___ga"
  }, {
    path: "/ga/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___ga"
  }, {
    path: "/ga/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___ga"
  }, {
    path: "/ga/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___ga"
  }, {
    path: "/ga/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___ga"
  }, {
    path: "/hr/account/login",
    component: _059fd4be,
    name: "account-login___hr"
  }, {
    path: "/hr/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___hr"
  }, {
    path: "/hr/collections/places",
    component: _60a7a7d9,
    name: "collections-places___hr"
  }, {
    path: "/hr/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___hr"
  }, {
    path: "/hr/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___hr"
  }, {
    path: "/hr/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___hr"
  }, {
    path: "/hr/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___hr"
  }, {
    path: "/hr/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___hr"
  }, {
    path: "/hr/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___hr"
  }, {
    path: "/hr/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___hr"
  }, {
    path: "/hr/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___hr"
  }, {
    path: "/hr/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___hr"
  }, {
    path: "/hu/account/login",
    component: _059fd4be,
    name: "account-login___hu"
  }, {
    path: "/hu/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___hu"
  }, {
    path: "/hu/collections/places",
    component: _60a7a7d9,
    name: "collections-places___hu"
  }, {
    path: "/hu/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___hu"
  }, {
    path: "/hu/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___hu"
  }, {
    path: "/hu/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___hu"
  }, {
    path: "/hu/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___hu"
  }, {
    path: "/hu/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___hu"
  }, {
    path: "/hu/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___hu"
  }, {
    path: "/hu/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___hu"
  }, {
    path: "/hu/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___hu"
  }, {
    path: "/hu/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___hu"
  }, {
    path: "/it/account/login",
    component: _059fd4be,
    name: "account-login___it"
  }, {
    path: "/it/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___it"
  }, {
    path: "/it/collections/places",
    component: _60a7a7d9,
    name: "collections-places___it"
  }, {
    path: "/it/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___it"
  }, {
    path: "/it/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___it"
  }, {
    path: "/it/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___it"
  }, {
    path: "/it/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___it"
  }, {
    path: "/it/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___it"
  }, {
    path: "/it/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___it"
  }, {
    path: "/it/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___it"
  }, {
    path: "/it/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___it"
  }, {
    path: "/it/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___it"
  }, {
    path: "/lt/account/login",
    component: _059fd4be,
    name: "account-login___lt"
  }, {
    path: "/lt/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___lt"
  }, {
    path: "/lt/collections/places",
    component: _60a7a7d9,
    name: "collections-places___lt"
  }, {
    path: "/lt/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___lt"
  }, {
    path: "/lt/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___lt"
  }, {
    path: "/lt/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___lt"
  }, {
    path: "/lt/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___lt"
  }, {
    path: "/lt/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___lt"
  }, {
    path: "/lt/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___lt"
  }, {
    path: "/lt/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___lt"
  }, {
    path: "/lt/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___lt"
  }, {
    path: "/lt/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___lt"
  }, {
    path: "/lv/account/login",
    component: _059fd4be,
    name: "account-login___lv"
  }, {
    path: "/lv/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___lv"
  }, {
    path: "/lv/collections/places",
    component: _60a7a7d9,
    name: "collections-places___lv"
  }, {
    path: "/lv/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___lv"
  }, {
    path: "/lv/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___lv"
  }, {
    path: "/lv/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___lv"
  }, {
    path: "/lv/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___lv"
  }, {
    path: "/lv/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___lv"
  }, {
    path: "/lv/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___lv"
  }, {
    path: "/lv/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___lv"
  }, {
    path: "/lv/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___lv"
  }, {
    path: "/lv/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___lv"
  }, {
    path: "/mt/account/login",
    component: _059fd4be,
    name: "account-login___mt"
  }, {
    path: "/mt/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___mt"
  }, {
    path: "/mt/collections/places",
    component: _60a7a7d9,
    name: "collections-places___mt"
  }, {
    path: "/mt/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___mt"
  }, {
    path: "/mt/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___mt"
  }, {
    path: "/mt/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___mt"
  }, {
    path: "/mt/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___mt"
  }, {
    path: "/mt/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___mt"
  }, {
    path: "/mt/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___mt"
  }, {
    path: "/mt/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___mt"
  }, {
    path: "/mt/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___mt"
  }, {
    path: "/mt/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___mt"
  }, {
    path: "/nl/account/login",
    component: _059fd4be,
    name: "account-login___nl"
  }, {
    path: "/nl/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___nl"
  }, {
    path: "/nl/collections/places",
    component: _60a7a7d9,
    name: "collections-places___nl"
  }, {
    path: "/nl/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___nl"
  }, {
    path: "/nl/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___nl"
  }, {
    path: "/nl/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___nl"
  }, {
    path: "/nl/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___nl"
  }, {
    path: "/nl/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___nl"
  }, {
    path: "/nl/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___nl"
  }, {
    path: "/nl/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___nl"
  }, {
    path: "/nl/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___nl"
  }, {
    path: "/nl/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___nl"
  }, {
    path: "/pl/account/login",
    component: _059fd4be,
    name: "account-login___pl"
  }, {
    path: "/pl/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___pl"
  }, {
    path: "/pl/collections/places",
    component: _60a7a7d9,
    name: "collections-places___pl"
  }, {
    path: "/pl/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___pl"
  }, {
    path: "/pl/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___pl"
  }, {
    path: "/pl/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___pl"
  }, {
    path: "/pl/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___pl"
  }, {
    path: "/pl/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___pl"
  }, {
    path: "/pl/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___pl"
  }, {
    path: "/pl/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___pl"
  }, {
    path: "/pl/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___pl"
  }, {
    path: "/pl/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___pl"
  }, {
    path: "/pt/account/login",
    component: _059fd4be,
    name: "account-login___pt"
  }, {
    path: "/pt/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___pt"
  }, {
    path: "/pt/collections/places",
    component: _60a7a7d9,
    name: "collections-places___pt"
  }, {
    path: "/pt/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___pt"
  }, {
    path: "/pt/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___pt"
  }, {
    path: "/pt/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___pt"
  }, {
    path: "/pt/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___pt"
  }, {
    path: "/pt/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___pt"
  }, {
    path: "/pt/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___pt"
  }, {
    path: "/pt/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___pt"
  }, {
    path: "/pt/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___pt"
  }, {
    path: "/pt/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___pt"
  }, {
    path: "/ro/account/login",
    component: _059fd4be,
    name: "account-login___ro"
  }, {
    path: "/ro/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___ro"
  }, {
    path: "/ro/collections/places",
    component: _60a7a7d9,
    name: "collections-places___ro"
  }, {
    path: "/ro/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___ro"
  }, {
    path: "/ro/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___ro"
  }, {
    path: "/ro/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___ro"
  }, {
    path: "/ro/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___ro"
  }, {
    path: "/ro/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___ro"
  }, {
    path: "/ro/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___ro"
  }, {
    path: "/ro/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___ro"
  }, {
    path: "/ro/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___ro"
  }, {
    path: "/ro/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___ro"
  }, {
    path: "/sk/account/login",
    component: _059fd4be,
    name: "account-login___sk"
  }, {
    path: "/sk/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___sk"
  }, {
    path: "/sk/collections/places",
    component: _60a7a7d9,
    name: "collections-places___sk"
  }, {
    path: "/sk/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___sk"
  }, {
    path: "/sk/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___sk"
  }, {
    path: "/sk/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___sk"
  }, {
    path: "/sk/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___sk"
  }, {
    path: "/sk/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___sk"
  }, {
    path: "/sk/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___sk"
  }, {
    path: "/sk/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___sk"
  }, {
    path: "/sk/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___sk"
  }, {
    path: "/sk/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___sk"
  }, {
    path: "/sl/account/login",
    component: _059fd4be,
    name: "account-login___sl"
  }, {
    path: "/sl/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___sl"
  }, {
    path: "/sl/collections/places",
    component: _60a7a7d9,
    name: "collections-places___sl"
  }, {
    path: "/sl/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___sl"
  }, {
    path: "/sl/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___sl"
  }, {
    path: "/sl/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___sl"
  }, {
    path: "/sl/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___sl"
  }, {
    path: "/sl/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___sl"
  }, {
    path: "/sl/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___sl"
  }, {
    path: "/sl/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___sl"
  }, {
    path: "/sl/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___sl"
  }, {
    path: "/sl/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___sl"
  }, {
    path: "/sv/account/login",
    component: _059fd4be,
    name: "account-login___sv"
  }, {
    path: "/sv/collections/persons",
    component: _60a7a7d9,
    name: "collections-persons___sv"
  }, {
    path: "/sv/collections/places",
    component: _60a7a7d9,
    name: "collections-places___sv"
  }, {
    path: "/sv/contentful/category-suggest",
    component: _374754c6,
    name: "contentful-category-suggest___sv"
  }, {
    path: "/sv/contentful/disabled-single-line",
    component: _15ffd630,
    name: "contentful-disabled-single-line___sv"
  }, {
    path: "/sv/contentful/entity-harvester",
    component: _6ef8d8d8,
    name: "contentful-entity-harvester___sv"
  }, {
    path: "/sv/contentful/entity-suggest",
    component: _ce4e66fc,
    name: "contentful-entity-suggest___sv"
  }, {
    path: "/sv/contentful/image-harvester",
    component: _0cb2bd5c,
    name: "contentful-image-harvester___sv"
  }, {
    path: "/sv/contentful/record-harvester",
    component: _26519902,
    name: "contentful-record-harvester___sv"
  }, {
    path: "/sv/contentful/set-suggest",
    component: _528a19c7,
    name: "contentful-set-suggest___sv"
  }, {
    path: "/sv/contentful/slug-validation",
    component: _d8b2bcee,
    name: "contentful-slug-validation___sv"
  }, {
    path: "/sv/debug/oembed",
    component: _0dbf7648,
    name: "debug-oembed___sv"
  }, {
    path: "/bg/collections/:type",
    component: _107b567c,
    name: "collections-type___bg"
  }, {
    path: "/bg/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___bg"
  }, {
    path: "/cs/collections/:type",
    component: _107b567c,
    name: "collections-type___cs"
  }, {
    path: "/cs/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___cs"
  }, {
    path: "/da/collections/:type",
    component: _107b567c,
    name: "collections-type___da"
  }, {
    path: "/da/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___da"
  }, {
    path: "/de/collections/:type",
    component: _107b567c,
    name: "collections-type___de"
  }, {
    path: "/de/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___de"
  }, {
    path: "/el/collections/:type",
    component: _107b567c,
    name: "collections-type___el"
  }, {
    path: "/el/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___el"
  }, {
    path: "/en/collections/:type",
    component: _107b567c,
    name: "collections-type___en"
  }, {
    path: "/en/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___en"
  }, {
    path: "/es/collections/:type",
    component: _107b567c,
    name: "collections-type___es"
  }, {
    path: "/es/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___es"
  }, {
    path: "/et/collections/:type",
    component: _107b567c,
    name: "collections-type___et"
  }, {
    path: "/et/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___et"
  }, {
    path: "/eu/collections/:type",
    component: _107b567c,
    name: "collections-type___eu"
  }, {
    path: "/eu/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___eu"
  }, {
    path: "/fi/collections/:type",
    component: _107b567c,
    name: "collections-type___fi"
  }, {
    path: "/fi/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___fi"
  }, {
    path: "/fr/collections/:type",
    component: _107b567c,
    name: "collections-type___fr"
  }, {
    path: "/fr/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___fr"
  }, {
    path: "/ga/collections/:type",
    component: _107b567c,
    name: "collections-type___ga"
  }, {
    path: "/ga/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___ga"
  }, {
    path: "/hr/collections/:type",
    component: _107b567c,
    name: "collections-type___hr"
  }, {
    path: "/hr/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___hr"
  }, {
    path: "/hu/collections/:type",
    component: _107b567c,
    name: "collections-type___hu"
  }, {
    path: "/hu/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___hu"
  }, {
    path: "/it/collections/:type",
    component: _107b567c,
    name: "collections-type___it"
  }, {
    path: "/it/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___it"
  }, {
    path: "/lt/collections/:type",
    component: _107b567c,
    name: "collections-type___lt"
  }, {
    path: "/lt/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___lt"
  }, {
    path: "/lv/collections/:type",
    component: _107b567c,
    name: "collections-type___lv"
  }, {
    path: "/lv/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___lv"
  }, {
    path: "/mt/collections/:type",
    component: _107b567c,
    name: "collections-type___mt"
  }, {
    path: "/mt/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___mt"
  }, {
    path: "/nl/collections/:type",
    component: _107b567c,
    name: "collections-type___nl"
  }, {
    path: "/nl/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___nl"
  }, {
    path: "/pl/collections/:type",
    component: _107b567c,
    name: "collections-type___pl"
  }, {
    path: "/pl/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___pl"
  }, {
    path: "/pt/collections/:type",
    component: _107b567c,
    name: "collections-type___pt"
  }, {
    path: "/pt/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___pt"
  }, {
    path: "/ro/collections/:type",
    component: _107b567c,
    name: "collections-type___ro"
  }, {
    path: "/ro/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___ro"
  }, {
    path: "/sk/collections/:type",
    component: _107b567c,
    name: "collections-type___sk"
  }, {
    path: "/sk/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___sk"
  }, {
    path: "/sl/collections/:type",
    component: _107b567c,
    name: "collections-type___sl"
  }, {
    path: "/sl/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___sl"
  }, {
    path: "/sv/collections/:type",
    component: _107b567c,
    name: "collections-type___sv"
  }, {
    path: "/sv/exhibitions/:exhibition",
    component: _49e81f40,
    name: "exhibitions-exhibition___sv"
  }, {
    path: "/bg/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___bg"
  }, {
    path: "/cs/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___cs"
  }, {
    path: "/da/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___da"
  }, {
    path: "/de/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___de"
  }, {
    path: "/el/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___el"
  }, {
    path: "/en/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___en"
  }, {
    path: "/es/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___es"
  }, {
    path: "/et/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___et"
  }, {
    path: "/eu/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___eu"
  }, {
    path: "/fi/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___fi"
  }, {
    path: "/fr/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___fr"
  }, {
    path: "/ga/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___ga"
  }, {
    path: "/hr/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___hr"
  }, {
    path: "/hu/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___hu"
  }, {
    path: "/it/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___it"
  }, {
    path: "/lt/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___lt"
  }, {
    path: "/lv/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___lv"
  }, {
    path: "/mt/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___mt"
  }, {
    path: "/nl/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___nl"
  }, {
    path: "/pl/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___pl"
  }, {
    path: "/pt/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___pt"
  }, {
    path: "/ro/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___ro"
  }, {
    path: "/sk/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___sk"
  }, {
    path: "/sl/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___sl"
  }, {
    path: "/sv/exhibitions/:exhibition/credits",
    component: _4e35a988,
    name: "exhibitions-exhibition-credits___sv"
  }, {
    path: "/bg/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___bg"
  }, {
    path: "/cs/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___cs"
  }, {
    path: "/da/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___da"
  }, {
    path: "/de/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___de"
  }, {
    path: "/el/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___el"
  }, {
    path: "/en/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___en"
  }, {
    path: "/es/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___es"
  }, {
    path: "/et/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___et"
  }, {
    path: "/eu/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___eu"
  }, {
    path: "/fi/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___fi"
  }, {
    path: "/fr/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___fr"
  }, {
    path: "/ga/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___ga"
  }, {
    path: "/hr/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___hr"
  }, {
    path: "/hu/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___hu"
  }, {
    path: "/it/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___it"
  }, {
    path: "/lt/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___lt"
  }, {
    path: "/lv/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___lv"
  }, {
    path: "/mt/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___mt"
  }, {
    path: "/nl/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___nl"
  }, {
    path: "/pl/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___pl"
  }, {
    path: "/pt/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___pt"
  }, {
    path: "/ro/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___ro"
  }, {
    path: "/sk/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___sk"
  }, {
    path: "/sl/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___sl"
  }, {
    path: "/sv/exhibitions/:exhibition/:chapter",
    component: _f9792be0,
    name: "exhibitions-exhibition-chapter___sv"
  }, {
    path: "/sv/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___sv"
  }, {
    path: "/sl/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___sl"
  }, {
    path: "/sk/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___sk"
  }, {
    path: "/ro/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___ro"
  }, {
    path: "/pt/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___pt"
  }, {
    path: "/pl/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___pl"
  }, {
    path: "/nl/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___nl"
  }, {
    path: "/mt/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___mt"
  }, {
    path: "/lv/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___lv"
  }, {
    path: "/lt/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___lt"
  }, {
    path: "/it/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___it"
  }, {
    path: "/hu/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___hu"
  }, {
    path: "/hr/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___hr"
  }, {
    path: "/ga/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___ga"
  }, {
    path: "/fr/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___fr"
  }, {
    path: "/fi/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___fi"
  }, {
    path: "/eu/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___eu"
  }, {
    path: "/et/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___et"
  }, {
    path: "/es/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___es"
  }, {
    path: "/en/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___en"
  }, {
    path: "/el/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___el"
  }, {
    path: "/de/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___de"
  }, {
    path: "/da/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___da"
  }, {
    path: "/cs/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___cs"
  }, {
    path: "/bg/collections/:type?/*",
    component: _b55f0eee,
    name: "collections-type-all___bg"
  }, {
    path: "/sv/blog/*",
    component: _05b8d788,
    name: "blog-all___sv"
  }, {
    path: "/sl/blog/*",
    component: _05b8d788,
    name: "blog-all___sl"
  }, {
    path: "/sk/blog/*",
    component: _05b8d788,
    name: "blog-all___sk"
  }, {
    path: "/ro/blog/*",
    component: _05b8d788,
    name: "blog-all___ro"
  }, {
    path: "/pt/blog/*",
    component: _05b8d788,
    name: "blog-all___pt"
  }, {
    path: "/pl/blog/*",
    component: _05b8d788,
    name: "blog-all___pl"
  }, {
    path: "/nl/blog/*",
    component: _05b8d788,
    name: "blog-all___nl"
  }, {
    path: "/mt/blog/*",
    component: _05b8d788,
    name: "blog-all___mt"
  }, {
    path: "/lv/blog/*",
    component: _05b8d788,
    name: "blog-all___lv"
  }, {
    path: "/lt/blog/*",
    component: _05b8d788,
    name: "blog-all___lt"
  }, {
    path: "/it/blog/*",
    component: _05b8d788,
    name: "blog-all___it"
  }, {
    path: "/hu/blog/*",
    component: _05b8d788,
    name: "blog-all___hu"
  }, {
    path: "/hr/blog/*",
    component: _05b8d788,
    name: "blog-all___hr"
  }, {
    path: "/ga/blog/*",
    component: _05b8d788,
    name: "blog-all___ga"
  }, {
    path: "/fr/blog/*",
    component: _05b8d788,
    name: "blog-all___fr"
  }, {
    path: "/fi/blog/*",
    component: _05b8d788,
    name: "blog-all___fi"
  }, {
    path: "/eu/blog/*",
    component: _05b8d788,
    name: "blog-all___eu"
  }, {
    path: "/et/blog/*",
    component: _05b8d788,
    name: "blog-all___et"
  }, {
    path: "/es/blog/*",
    component: _05b8d788,
    name: "blog-all___es"
  }, {
    path: "/en/blog/*",
    component: _05b8d788,
    name: "blog-all___en"
  }, {
    path: "/el/blog/*",
    component: _05b8d788,
    name: "blog-all___el"
  }, {
    path: "/de/blog/*",
    component: _05b8d788,
    name: "blog-all___de"
  }, {
    path: "/da/blog/*",
    component: _05b8d788,
    name: "blog-all___da"
  }, {
    path: "/cs/blog/*",
    component: _05b8d788,
    name: "blog-all___cs"
  }, {
    path: "/bg/blog/*",
    component: _05b8d788,
    name: "blog-all___bg"
  }, {
    path: "/sv/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___sv"
  }, {
    path: "/sl/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___sl"
  }, {
    path: "/sk/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___sk"
  }, {
    path: "/ro/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___ro"
  }, {
    path: "/pt/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___pt"
  }, {
    path: "/pl/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___pl"
  }, {
    path: "/nl/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___nl"
  }, {
    path: "/mt/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___mt"
  }, {
    path: "/lv/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___lv"
  }, {
    path: "/lt/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___lt"
  }, {
    path: "/it/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___it"
  }, {
    path: "/hu/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___hu"
  }, {
    path: "/hr/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___hr"
  }, {
    path: "/ga/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___ga"
  }, {
    path: "/fr/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___fr"
  }, {
    path: "/fi/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___fi"
  }, {
    path: "/eu/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___eu"
  }, {
    path: "/et/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___et"
  }, {
    path: "/es/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___es"
  }, {
    path: "/en/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___en"
  }, {
    path: "/el/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___el"
  }, {
    path: "/de/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___de"
  }, {
    path: "/da/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___da"
  }, {
    path: "/cs/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___cs"
  }, {
    path: "/bg/galleries/*",
    component: _0ddb261a,
    name: "galleries-all___bg"
  }, {
    path: "/sv/item/*",
    component: _f70feb8e,
    name: "item-all___sv"
  }, {
    path: "/sl/item/*",
    component: _f70feb8e,
    name: "item-all___sl"
  }, {
    path: "/sk/item/*",
    component: _f70feb8e,
    name: "item-all___sk"
  }, {
    path: "/ro/item/*",
    component: _f70feb8e,
    name: "item-all___ro"
  }, {
    path: "/pt/item/*",
    component: _f70feb8e,
    name: "item-all___pt"
  }, {
    path: "/pl/item/*",
    component: _f70feb8e,
    name: "item-all___pl"
  }, {
    path: "/nl/item/*",
    component: _f70feb8e,
    name: "item-all___nl"
  }, {
    path: "/mt/item/*",
    component: _f70feb8e,
    name: "item-all___mt"
  }, {
    path: "/lv/item/*",
    component: _f70feb8e,
    name: "item-all___lv"
  }, {
    path: "/lt/item/*",
    component: _f70feb8e,
    name: "item-all___lt"
  }, {
    path: "/it/item/*",
    component: _f70feb8e,
    name: "item-all___it"
  }, {
    path: "/hu/item/*",
    component: _f70feb8e,
    name: "item-all___hu"
  }, {
    path: "/hr/item/*",
    component: _f70feb8e,
    name: "item-all___hr"
  }, {
    path: "/ga/item/*",
    component: _f70feb8e,
    name: "item-all___ga"
  }, {
    path: "/fr/item/*",
    component: _f70feb8e,
    name: "item-all___fr"
  }, {
    path: "/fi/item/*",
    component: _f70feb8e,
    name: "item-all___fi"
  }, {
    path: "/eu/item/*",
    component: _f70feb8e,
    name: "item-all___eu"
  }, {
    path: "/et/item/*",
    component: _f70feb8e,
    name: "item-all___et"
  }, {
    path: "/es/item/*",
    component: _f70feb8e,
    name: "item-all___es"
  }, {
    path: "/en/item/*",
    component: _f70feb8e,
    name: "item-all___en"
  }, {
    path: "/el/item/*",
    component: _f70feb8e,
    name: "item-all___el"
  }, {
    path: "/de/item/*",
    component: _f70feb8e,
    name: "item-all___de"
  }, {
    path: "/da/item/*",
    component: _f70feb8e,
    name: "item-all___da"
  }, {
    path: "/cs/item/*",
    component: _f70feb8e,
    name: "item-all___cs"
  }, {
    path: "/bg/item/*",
    component: _f70feb8e,
    name: "item-all___bg"
  }, {
    path: "/sv/themes/*",
    component: _a75613a0,
    name: "themes-all___sv"
  }, {
    path: "/sl/themes/*",
    component: _a75613a0,
    name: "themes-all___sl"
  }, {
    path: "/sk/themes/*",
    component: _a75613a0,
    name: "themes-all___sk"
  }, {
    path: "/ro/themes/*",
    component: _a75613a0,
    name: "themes-all___ro"
  }, {
    path: "/pt/themes/*",
    component: _a75613a0,
    name: "themes-all___pt"
  }, {
    path: "/pl/themes/*",
    component: _a75613a0,
    name: "themes-all___pl"
  }, {
    path: "/nl/themes/*",
    component: _a75613a0,
    name: "themes-all___nl"
  }, {
    path: "/mt/themes/*",
    component: _a75613a0,
    name: "themes-all___mt"
  }, {
    path: "/lv/themes/*",
    component: _a75613a0,
    name: "themes-all___lv"
  }, {
    path: "/lt/themes/*",
    component: _a75613a0,
    name: "themes-all___lt"
  }, {
    path: "/it/themes/*",
    component: _a75613a0,
    name: "themes-all___it"
  }, {
    path: "/hu/themes/*",
    component: _a75613a0,
    name: "themes-all___hu"
  }, {
    path: "/hr/themes/*",
    component: _a75613a0,
    name: "themes-all___hr"
  }, {
    path: "/ga/themes/*",
    component: _a75613a0,
    name: "themes-all___ga"
  }, {
    path: "/fr/themes/*",
    component: _a75613a0,
    name: "themes-all___fr"
  }, {
    path: "/fi/themes/*",
    component: _a75613a0,
    name: "themes-all___fi"
  }, {
    path: "/eu/themes/*",
    component: _a75613a0,
    name: "themes-all___eu"
  }, {
    path: "/et/themes/*",
    component: _a75613a0,
    name: "themes-all___et"
  }, {
    path: "/es/themes/*",
    component: _a75613a0,
    name: "themes-all___es"
  }, {
    path: "/en/themes/*",
    component: _a75613a0,
    name: "themes-all___en"
  }, {
    path: "/el/themes/*",
    component: _a75613a0,
    name: "themes-all___el"
  }, {
    path: "/de/themes/*",
    component: _a75613a0,
    name: "themes-all___de"
  }, {
    path: "/da/themes/*",
    component: _a75613a0,
    name: "themes-all___da"
  }, {
    path: "/cs/themes/*",
    component: _a75613a0,
    name: "themes-all___cs"
  }, {
    path: "/bg/themes/*",
    component: _a75613a0,
    name: "themes-all___bg"
  }, {
    path: "/sv/*",
    component: _57509004,
    name: "slug___sv"
  }, {
    path: "/sl/*",
    component: _57509004,
    name: "slug___sl"
  }, {
    path: "/sk/*",
    component: _57509004,
    name: "slug___sk"
  }, {
    path: "/ro/*",
    component: _57509004,
    name: "slug___ro"
  }, {
    path: "/pt/*",
    component: _57509004,
    name: "slug___pt"
  }, {
    path: "/pl/*",
    component: _57509004,
    name: "slug___pl"
  }, {
    path: "/nl/*",
    component: _57509004,
    name: "slug___nl"
  }, {
    path: "/mt/*",
    component: _57509004,
    name: "slug___mt"
  }, {
    path: "/lv/*",
    component: _57509004,
    name: "slug___lv"
  }, {
    path: "/lt/*",
    component: _57509004,
    name: "slug___lt"
  }, {
    path: "/it/*",
    component: _57509004,
    name: "slug___it"
  }, {
    path: "/hu/*",
    component: _57509004,
    name: "slug___hu"
  }, {
    path: "/hr/*",
    component: _57509004,
    name: "slug___hr"
  }, {
    path: "/ga/*",
    component: _57509004,
    name: "slug___ga"
  }, {
    path: "/fr/*",
    component: _57509004,
    name: "slug___fr"
  }, {
    path: "/fi/*",
    component: _57509004,
    name: "slug___fi"
  }, {
    path: "/eu/*",
    component: _57509004,
    name: "slug___eu"
  }, {
    path: "/et/*",
    component: _57509004,
    name: "slug___et"
  }, {
    path: "/es/*",
    component: _57509004,
    name: "slug___es"
  }, {
    path: "/en/*",
    component: _57509004,
    name: "slug___en"
  }, {
    path: "/el/*",
    component: _57509004,
    name: "slug___el"
  }, {
    path: "/de/*",
    component: _57509004,
    name: "slug___de"
  }, {
    path: "/da/*",
    component: _57509004,
    name: "slug___da"
  }, {
    path: "/cs/*",
    component: _57509004,
    name: "slug___cs"
  }, {
    path: "/bg/*",
    component: _57509004,
    name: "slug___bg"
  }],

  parseQuery: (query) => require('qs').parse(query),
  stringifyQuery: query => {
  const stringified = require('qs').stringify(query, { arrayFormat: 'repeat' });
  return stringified ? '?' + stringified : '';
},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
